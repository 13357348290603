import * as React from "react"
import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";

import regulamin from "../files/konkurs_regulamin.pdf"
import QRCode from "react-qr-code";
import pdfIcon from "../images/pdf.svg"
import konkurs from "../images/konkurs.png"


const KonkursPage = () => {
    return (
        <DefaultLayout>
            <SEO title="Konkurs" />
            <article className="prose prose-xl">
                <img className="h-96 mx-auto" src={konkurs} alt="konkurs"></img>
                {/* <img className="h-16 mx-auto" src={jakKorzystacTekst} alt="jak korzystać"></img> */}
                <p><b>Organizatorem</b> konkursu jest Fundacja Ludzi Otwartej Wyobraźni FLOW z siedzibą w Konstancinie-Jeziornie 05-520 przy ul. Mirkowskiej 39B</p>
                <p><b>Celem Konkursu</b> jest promowanie edukacji terenowej jako formy rozwijającej kluczowe kompetencje, takie jak: kreatywność, współpracę, komunikację i krytyczne myślenie. </p>
                <p><b>Zadanie konkursowe</b> polega na przejściu trasy przez zespół uczniowski pod opieką nauczyciela i wykonaniu zadań jednego wybranego scenariusza spośród dostępnych na stronie mapaedukacyjna.pl oraz udokumentowaniu przygody z Edumapą.</p>

                <p><a className="flex" href={regulamin} download>
                    <img src={pdfIcon} alt="pdf" className='h-8 my-auto mr-3' />
                    <span>Regulamin konkursu</span>
                </a></p>

                <p>Wypełnijcie i wyślijcie formularz zgłoszeniowy dostępny pod <a href="https://docs.google.com/forms/d/e/1FAIpQLSdoNxsBHAQEHsfmtxoNLvLhPqrRw1iVxy1gidc2u29lkcianQ/viewform">linkiem</a> lub pod kodem QR.</p>

                <p>Na prace konkursowe czekamy do <b>31 maja 2023 r.</b></p>

                <QRCode value="https://docs.google.com/forms/d/e/1FAIpQLSdoNxsBHAQEHsfmtxoNLvLhPqrRw1iVxy1gidc2u29lkcianQ/viewform" />

                <p>Wypełnijcie ankietę - każda osoba z Zespołu oraz opiekun wypełnia <a href="https://docs.google.com/forms/d/e/1FAIpQLScdNIfuHWwQAdlxifNcLXnpwDzfbdinTtbHdLLkzvButeWlyw/viewform">ankietę</a> dostępną też pod kodem QR</p>

                <QRCode value="https://docs.google.com/forms/d/e/1FAIpQLScdNIfuHWwQAdlxifNcLXnpwDzfbdinTtbHdLLkzvButeWlyw/viewform" />


            </article>

        </DefaultLayout>
    )
}

export default KonkursPage